import React from 'react'
import { t } from 'i18next'
import { uniqBy } from 'lodash'

export const getCountryOptions = countries => {
  return countries.map(({ node: { id, title, slug, countryRegionSlug } }) => ({
    id,
    text: title,
    value: `${JSON.stringify({
      countrySlug: slug,
      country: title,
      countryRegionSlug: countryRegionSlug
    })}`,
    content: (
      <p>
        {title} <span>({t('finder.country')})</span>
      </p>
    )
  }))
}

export const getCountryRegionOptions = countries => {
  const uniqueRegions = uniqBy(countries, 'node.countryRegion')

  return uniqueRegions.map(
    ({ node: { id, countryRegion, countryRegionSlug } }) => ({
      id,
      text: countryRegion,
      value: `${JSON.stringify({
        countryRegion: countryRegion,
        countryRegionSlug: countryRegionSlug
      })}`,
      content: <p>{countryRegion}</p>
    })
  )
}

export const getRegionOptions = regions => {
  return regions.map(
    ({
      node: { id, title, slug, country, countrySlug, countryRegionSlug }
    }) => ({
      id,
      text: title,
      value: `${JSON.stringify({
        countrySlug,
        countryRegionSlug: countryRegionSlug,
        country,
        region: title,
        regionSlug: slug
      })}`,
      description: `${country}`,
      content: (
        <p>
          {title} <span>({t('finder.region')})</span>
        </p>
      )
    })
  )
}

export const getLocationOptions = locations => {
  return locations.map(
    ({
      node: {
        id,
        title,
        slug,
        country,
        countrySlug,
        region,
        regionSlug,
        countryRegionSlug
      }
    }) => ({
      id,
      text: title,
      value: `${JSON.stringify({
        countrySlug,
        countryRegionSlug: countryRegionSlug,
        country,
        region,
        regionSlug,
        location: title,
        locationSlug: slug
      })}`,
      description: `${country} / ${region}`,
      content: (
        <p>
          {title} <span>({t('finder.location')})</span>
        </p>
      )
    })
  )
}

export const getFinderOptions = (countries, regions, locations) => {
  return [
    ...getCountryOptions(countries),
    ...getCountryRegionOptions(countries),
    ...getRegionOptions(regions),
    ...getLocationOptions(locations)
  ]
}
