/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from 'react'
import { Provider } from 'react-redux'
import { I18nextProvider } from 'react-i18next'
import i18n from './src/locales'

import createStore from './src/state/createStore'
import { loadState, saveState } from './src/state/localStorage'

import './src/stylesheets/semantic/dist/semantic.min.css'
import './node_modules/rc-slider/assets/index.css'
import './node_modules/slick-carousel/slick/slick.css'
import './node_modules/slick-carousel/slick/slick-theme.css'

import './src/stylesheets/index.less'

import firebase from 'firebase/app'
firebase.initializeApp({
  apiKey: process.env.FIREBASE_API_KEY,
  projectId: process.env.FIREBASE_PROJECT_ID
})

const persistedState = loadState()
const store = createStore(persistedState)

store.subscribe(() => {
  saveState({
    wishlist: store.getState().wishlist
  })
})

// const detectLanguage = () => {
//   const segments = window.location.pathname.split('/')
//   if (segments[1]) {
//     return ['de'].includes(segments[1]) ? segments[1] : 'de'
//   }

//   let found = []
//   let lang = []

//   if (typeof navigator !== 'undefined') {
//     if (navigator.languages) {
//       for (let i = 0; i < navigator.languages.length; i++) {
//         found.push(navigator.languages[i])
//       }
//     }
//     if (navigator.userLanguage) {
//       found.push(navigator.userLanguage)
//     }
//     if (navigator.language) {
//       found.push(navigator.language)
//     }
//   }

//   found.forEach(f => {
//     if (['de'].includes(f)) {
//       lang.push(f)
//     }
//   })

//   return lang.length > 0 ? lang[0] : 'de'
// }

// const lang = detectLanguage()

// if (window.location.pathname === '/' && lang === 'fr') {
//   window.location.replace(`${window.location.origin}/fr`)
// }

// i18n.changeLanguage('de')

export const wrapRootElement = ({ element }) => {
  const ConnectedRootElement = (
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>{element}</Provider>
    </I18nextProvider>
  )

  return ConnectedRootElement
}
