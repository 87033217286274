import { combineReducers } from 'redux'

import { filtersReducer } from '../components/Filters/state'
import { wishlistReducer } from '../components/Wishlist/state'
import { modalReducer } from '../components/Modals/state'
import { googleMapsReducer } from '../components/CookieConfirmation/state'
import { navigationReducer } from '../components/Navigations/state'

export default combineReducers({
  filters: filtersReducer,
  wishlist: wishlistReducer,
  modals: modalReducer,
  googleMaps: googleMapsReducer,
  navigation: navigationReducer
})
