import { GOOGLE_MAPS_ENABLE, GOOGLE_MAPS_DISABLE } from './action-types'

export const googleMapsDisable = () => {
  return {
    type: GOOGLE_MAPS_DISABLE
  }
}

export const googleMapsEnable = () => {
  return {
    type: GOOGLE_MAPS_ENABLE
  }
}
