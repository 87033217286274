import React from 'react'
import { Link } from 'gatsby'

import { localizeLink } from '../utils/helpers'

const I18nLink = ({
  lang,
  to,
  partially,
  noRedirect,
  noActive,
  className,
  children,
  state,
  onClick
}) => {
  const toWithLang = localizeLink(`${to}`, lang)

  const isActive = ({ isCurrent }) => {
    return isCurrent && !noActive
      ? { className: `${className || ''} active` }
      : { className: `${className || ''}` }
  }

  const isPartiallyActive = ({ isPartiallyCurrent }) => {
    return isPartiallyCurrent && !noActive
      ? { className: `${className || ''} active` }
      : { className: `${className || ''}` }
  }

  const preventRedirect = e => {
    e.preventDefault()
    onClick()
  }

  return (
    <Link
      onClick={noRedirect ? preventRedirect : onClick}
      to={toWithLang}
      getProps={partially ? isPartiallyActive : isActive}
      state={state}
      className={className || ''}
      hrefLang={lang}
    >
      {children}
    </Link>
  )
}

export default I18nLink
