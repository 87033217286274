import {
  FILTERS_SET,
  FILTERS_RESET,
  FILTERS_INIT_DATA,
  FILTERS_ORDER,
  FILTERS_REMOVE_ITEMS,
  FILTERS_SERVICE_SET
} from './action-types'
import { encodeQueryFilters } from '../../../utils/helpers'
import { getFinderOptions } from '../../../utils/options'

const initialState = {
  options: {
    serviceGroup: '',
    country: '',
    countryRegion: '',
    region: '',
    location: '',
    service: '',
    stars: 0,
    duration: {
      min: 1,
      max: 18
    },
    hotelsCategories: [],
    toursCategories: [],
    tripsCategories: [],
    sharedCategories: []
  },
  init: {
    hotels: [],
    trips: [],
    tours: [],
    countries: [],
    regions: [],
    locations: [],
    allCategories: [],
    sharedCategories: [],
    hotelsCategories: [],
    toursCategories: [],
    tripsCategories: [],
    found: {
      total: 0,
      hotels: 0,
      tours: 0,
      trips: 0,
      init: true
    }
  },
  result: {
    hotels: [],
    trips: [],
    tours: [],
    countries: [],
    regions: [],
    locations: [],
    sharedCategories: [],
    hotelsCategories: [],
    toursCategories: [],
    tripsCategories: [],
    found: {
      total: 0,
      hotels: 0,
      tours: 0,
      trips: 0,
      init: true
    }
  },
  place: {
    found: {
      total: 0,
      hotels: 0,
      tours: 0,
      trips: 0,
      init: true
    }
  },
  sortOrder: 'title',
  finderOptions: []
}

export function filtersReducer (state = initialState, { payload, type }) {
  switch (type) {
    case FILTERS_RESET:
      return {
        ...state,
        options: {
          ...initialState.options,
          sharedCategories: [],
          hotelsCategories: [],
          toursCategories: [],
          tripsCategories: []
        }
      }

    case FILTERS_ORDER:
      return { ...filterSort({ ...state, sortOrder: payload }) }

    case FILTERS_SET:
      return {
        ...filterReload({
          ...state,
          result: { ...state.init },
          options: { ...state.options, ...payload }
        })
      }

    case FILTERS_SERVICE_SET:
      return {
        ...filterReload({
          ...state,
          result: { ...state.init },
          options: {
            ...state.options,
            ...payload,
            stars: 0,
            duration: { min: 1, max: 18 },
            hotelsCategories: [],
            toursCategories: [],
            tripsCategories: [],
            sharedCategories: []
          }
        })
      }

    case FILTERS_INIT_DATA:
      return { ...filterInitData({ ...state }, payload) }

    case FILTERS_REMOVE_ITEMS:
      const emptyPlaces = {
        hotels: [],
        tours: [],
        trips: [],
        found: { total: 0, hotels: 0, tours: 0, trips: 0 }
      }
      return {
        ...state,
        init: { ...state.init, ...emptyPlaces },
        result: { ...state.result, ...emptyPlaces },
        finderOptions: []
      }

    default:
      return state
  }
}

/**
 * Sort result items
 */
function filterSort (state) {
  const { result, sortOrder } = state
  const hotels = result.hotels.sort((a, b) =>
    a.node[sortOrder].localeCompare(b.node[sortOrder])
  )
  const tours = result.tours.sort((a, b) =>
    a.node[sortOrder].localeCompare(b.node[sortOrder])
  )
  const trips = result.trips.sort((a, b) =>
    a.node[sortOrder].localeCompare(b.node[sortOrder])
  )

  return { ...state, result: { ...state.result, hotels, tours, trips } }
}

/**
 * Sets initial data and filter by set options
 */
function filterInitData (state, { init, options }) {
  if (options.duration) {
    options.duration.min = Number(options.duration.min)
    options.duration.max = Number(options.duration.max)
  } else {
    options.duration = {
      min: 1,
      max: 18
    }
  }

  const finderOptions = getFinderOptions(
    init.countries,
    init.regions,
    init.locations
  )
  const newState = {
    ...filterReload({
      ...state,
      options: { ...state.options, ...options },
      init,
      result: init,
      finderOptions
    })
  }

  return newState
}

/**
 * Sets result data based on initial data and set options
 */
function filterReload (state) {
  const newState = { ...state }
  const { options } = newState

  // reset filter group
  newState.options.serviceGroup = ''
  newState.place = { found: { ...newState.result.found } }

  // update filter url
  if (typeof window !== 'undefined' && window.location && window.history) {
    const query = encodeQueryFilters(options)

    if (query !== window.location.search) {
      window.history.pushState(
        {},
        '',
        `${window.location.href.split('?')[0]}${query}`
      )
    }
  }

  // filter by countryRegion
  if (options.countryRegion) {
    newState.result = {
      ...newState.result,
      ...filterByCountryRegion({ data: { ...newState.result }, options })
    }
    newState.place = { found: { ...newState.result.found } }
  }

  // filter by country
  if (options.country) {
    newState.result = {
      ...newState.result,
      ...filterByCountry({ data: { ...newState.result }, options })
    }
    newState.place = { found: { ...newState.result.found } }
  }

  // filter by region
  if (options.region) {
    newState.result = {
      ...newState.result,
      ...filterByRegion({ data: { ...newState.result }, options })
    }
    newState.place = { found: { ...newState.result.found } }
  }

  // filter by location
  if (options.location) {
    newState.result = {
      ...newState.result,
      ...filterByLocation({ data: { ...newState.result }, options })
    }
    newState.place = { found: { ...newState.result.found } }
  }

  // filter by service
  if (options.service) {
    newState.result = {
      ...newState.result,
      ...filterByService({ data: { ...newState.result }, options })
    }
    newState.options.serviceGroup = options.service
  }

  // filter by duration
  if (options.duration.min > 1 || options.duration.max < 18) {
    newState.result = {
      ...newState.result,
      ...filterByDuration({ data: { ...newState.result }, options })
    }
    newState.options.serviceGroup = 'tours'
  }

  // filter by hotel stars
  if (options.stars) {
    newState.result = {
      ...newState.result,
      ...filterByStars({ data: { ...newState.result }, options })
    }
    newState.options.serviceGroup = 'hotels'
  }

  // filter by categories
  if (options.sharedCategories.length) {
    newState.result = {
      ...newState.result,
      ...filterByServiceCategories({
        data: { ...newState.result },
        service: 'shared',
        options: { ...newState.options }
      })
    }
  }
  if (options.hotelsCategories.length) {
    newState.result = {
      ...newState.result,
      ...filterByServiceCategories({
        data: { ...newState.result },
        service: 'hotels',
        options: { ...newState.options }
      })
    }
    newState.options.serviceGroup = 'hotels'
  }
  if (options.toursCategories.length) {
    newState.result = {
      ...newState.result,
      ...filterByServiceCategories({
        data: { ...newState.result },
        service: 'tours',
        options: { ...newState.options }
      })
    }
    newState.options.serviceGroup = 'tours'
  }
  if (options.tripsCategories.length) {
    newState.result = {
      ...newState.result,
      ...filterByServiceCategories({
        data: { ...newState.result },
        service: 'trips',
        options: { ...newState.options }
      })
    }
    newState.options.serviceGroup = 'trips'
  }

  // sync categories with result items
  newState.result = {
    ...newState.result,
    ...syncCategories({ data: { ...newState.result } })
  }

  // sort items
  return { ...filterSort({ ...newState }) }
}

/**
 * Filter items by countryRegion and returns result object
 */
function filterByCountryRegion ({ data, options: { countryRegion } }) {
  const hotels = data.hotels.filter(
    ({ node }) => node.countryRegion === countryRegion
  )
  const tours = data.tours.filter(
    ({ node }) => node.countryRegion === countryRegion
  )
  const trips = data.trips.filter(
    ({ node }) => node.countryRegion === countryRegion
  )
  const regions = data.regions.filter(
    ({ node }) => node.countryRegion === countryRegion
  )
  const countries = data.countries.filter(
    ({ node }) => node.countryRegion === countryRegion
  )
  const countryRegions = data.countries
  const locations = data.locations.filter(
    ({ node }) => node.countryRegion === countryRegion
  )
  const found = countResults(hotels, tours, trips)
  return {
    hotels,
    tours,
    trips,
    countries,
    countryRegions,
    regions,
    locations,
    found
  }
}

/**
 * Filter items by country and returns result object
 */
function filterByCountry ({ data, options: { country } }) {
  const hotels = data.hotels.filter(({ node }) => node.country === country)
  const tours = data.tours.filter(({ node }) => node.country === country)
  const trips = data.trips.filter(({ node }) => node.country === country)
  const regions = data.regions.filter(({ node }) => node.country === country)
  const locations = data.locations.filter(
    ({ node }) => node.country === country
  )
  const found = countResults(hotels, tours, trips)
  return { hotels, tours, trips, regions, locations, found }
}

/**
 * Filter items by region and returns result object
 */
function filterByRegion ({ data, options: { region } }) {
  const hotels = data.hotels.filter(({ node }) => node.region === region)
  const tours = data.tours.filter(({ node }) => node.region === region)
  const trips = data.trips.filter(({ node }) => node.region === region)
  const locations = data.locations.filter(({ node }) => node.region === region)
  const found = countResults(hotels, tours, trips)
  return { hotels, tours, trips, locations, found }
}

/**
 * Filter items by location and returns result object
 */
function filterByLocation ({ data, options: { region, location } }) {
  const hotels = data.hotels.filter(({ node }) => node.location === location)
  const tours = data.tours.filter(({ node }) => node.location === location)
  const trips = data.trips.filter(({ node }) => node.location === location)
  const locations = data.locations.filter(({ node }) => node.region === region)
  const found = countResults(hotels, tours, trips)
  return { hotels, tours, trips, locations, found }
}

/**
 * Filter items by location and returns result object
 */
function filterByService ({ data, options: { service } }) {
  const hotels = !service || service === 'hotels' ? data.hotels : []
  const tours = !service || service === 'tours' ? data.tours : []
  const trips = !service || service === 'trips' ? data.trips : []
  const found = countResults(hotels, tours, trips)
  return { hotels, tours, trips, found }
}

/**
 * Filter items by duration time in days and returns result object
 */
function filterByDuration ({
  data,
  options: {
    duration: { min, max }
  }
}) {
  if (!min) {
    min = 1
  }
  if (!max) {
    max = 18
  }

  const hotels = []
  const trips = []
  const tours = data.tours.filter(
    ({ node: { days } }) => days >= min && days <= max
  )

  const found = countResults(hotels, tours, trips)
  return { hotels, tours, trips, found }
}

/**
 * Filter items by hotel stars
 */
function filterByStars ({ data, options: { stars } }) {
  const hotels = data.hotels.filter(
    ({ node }) => node.stars >= stars && node.stars < stars + 1
  )
  const trips = []
  const tours = []

  const found = countResults(hotels, tours, trips)
  return { hotels, tours, trips, found }
}

/**
 * Returns items that contains selected categories
 */
function filterByCategories (items, categories) {
  return items.filter(({ node }) =>
    categories.every(category => {
      let allCategories = [...node.categories]
      node.categories.forEach(category => 
        allCategories.push(encodeURIComponent(category))
      )

      // decode URI first and then encode again to make it consistant
      return allCategories.includes(encodeURIComponent(decodeURIComponent(category)))
    })
  )
}

/**
 * Returns items that contains selected service categories
 */
function filterByServiceCategories ({ data, service, options }) {
  const hotels =
    service === 'shared' || service === 'hotels'
      ? filterByCategories(data.hotels, options[`${service}Categories`])
      : []
  const tours =
    service === 'shared' || service === 'tours'
      ? filterByCategories(data.tours, options[`${service}Categories`])
      : []
  const trips =
    service === 'shared' || service === 'trips'
      ? filterByCategories(data.trips, options[`${service}Categories`])
      : []

  const found = countResults(hotels, tours, trips)
  return { hotels, tours, trips, found }
}

/**
 * Helper for creating object with calculated number of items
 */
function countResults (hotels, tours, trips) {
  let foundHotels = hotels.length
  let foundTours = tours.length
  let foundTrips = trips.length
  let foundTotal = foundHotels + foundTours + foundTrips

  return {
    total: foundTotal,
    hotels: foundHotels,
    tours: foundTours,
    trips: foundTrips
  }
}

/**
 * Sync categories with the ones that actually exists in result items
 */
function syncCategories ({ data, data: { hotels, tours, trips } }) {
  const hotelsCategories = getItemsCategories(hotels, data.hotelsCategories)
  const toursCategories = getItemsCategories(tours, data.toursCategories)
  const tripsCategories = getItemsCategories(trips, data.tripsCategories)
  const sharedCategories = getItemsCategories(
    [...hotels, ...tours, ...trips],
    data.sharedCategories
  )

  return {
    hotelsCategories,
    toursCategories,
    tripsCategories,
    sharedCategories
  }
}

/**
 * Returns only categories that actually exists in result items
 */
function getItemsCategories (items, categories) {
  return categories.filter(category => {
    // we break the loop on first category occurance
    return items.some(({ node }) => node.categories.includes(category))
  })
}
