import { GOOGLE_MAPS_ENABLE, GOOGLE_MAPS_DISABLE } from './action-types'

export const INITIAL_STATE = {}

function addAnalyzeScript () {
  if (typeof document === 'undefined') {
    return false
  }

  // Init usercentrics GDPR cookie consent panel
  if (!document.getElementById('usercentrics-cmp')) {
    const UCs = document.createElement(`script`)
    UCs.setAttribute('id', 'usercentrics-cmp')
    UCs.setAttribute(
      'src',
      'https://app.usercentrics.eu/browser-ui/latest/loader.js'
    )
    UCs.setAttribute('data-settings-id', '5gd5mnQUH')
    UCs.defer = true
    document.body.appendChild(UCs)
  }

  // Google Tag Manager + usercentrics trigger (type is text/plain managed by data-usercentrics service)
  if (!document.getElementById('gtm-script')) {
    const GTMScript =
      `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','` +
      process.env.TAG_MANAGER_ID +
      `');`
    const s = document.createElement(`script`)
    s.setAttribute('id', 'gtm-script')
    s.setAttribute('data-usercentrics', 'Google Tag Manager')
    s.type = 'text/plain'
    s.defer = true
    s.innerText = GTMScript
    document.body.appendChild(s)
  }

  // Hubspot Tracking code
  if (!document.getElementById('hs-script-loader')) {
    const s2 = document.createElement('script')
    s2.setAttribute('id', 'hs-script-loader')
    s2.defer = true
    s2.setAttribute(
      'src',
      '//js.hs-scripts.com/' + process.env.HUBSPOT_PORTAL_ID + '.js'
    )
    s2.setAttribute('data-usercentrics', 'HubSpot')
    s2.type = 'text/plain'
    document.body.appendChild(s2)
  }
}

addAnalyzeScript()

export function googleMapsReducer (state = INITIAL_STATE, { type }) {
  switch (type) {
    case GOOGLE_MAPS_ENABLE:
      return { googleMapsEnable: 'true' }
    case GOOGLE_MAPS_DISABLE:
      return { googleMapsEnable: 'false' }

    default:
      return state
  }
}
